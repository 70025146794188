import React, { FC, ReactNode, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import useZendeskService, { closeAndHideZendeskWidget } from "@hooks/useZendeskService";
import { Loader } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";
import { IntercomProvider } from "react-use-intercom";
import { useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { Toaster } from "@components";
import AutologinLoader from "@components/Autologin/AutologinLoader";
import { InternalServerError, InactiveBranchError } from "@views/Errors";

import { useConfigurationStore, useUIStore } from "@stores";
import { useAuth, useGetGamificationSettings, useGetUserProfile } from "@hooks";
import { decodeBase64, languageChange, setActiveTheme } from "@utils/helpers";
import permissions from "@utils/permissions";
import { integrations } from "@config";

import { getCatalogSettings } from "@api/catalog";
import { getAnnouncements } from "@api/announcements";
import { getUserStatistics, getUserIntegrations } from "@api/user";
import {
  getDomainSettings,
  getDomainTerms,
  getSSOAuthUrls,
  getSocialDomainSettings,
} from "@api/app";

import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import localStorageKeys from "@constants/localStorageKeys";

type LayoutWrapperProps = {
  children: ReactNode;
};

const isBranchInactive = (errors: unknown[]): boolean => {
  return errors.some((error) => {
    const errorResponse = (error as AxiosError)?.response;
    const responseStatus = errorResponse?.status;
    const errorId = errorResponse?.data?._errors?.[0]?.id;

    return responseStatus === 403 && errorId === "forbidden.branch_inactive";
  });
};

const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();
  const {
    userProfileData,
    domainSettings,
    domainTerms,
    userIntegrations,
    setDomainSettings,
    setDomainTerms,
    setUserProfile,
    setGamificationSettings,
    setSsoDomainSettings,
    setSocialDomainSettings,
    setUserStatistics,
    setCatalogSettings,
    setAnnouncements,
    setUserIntegrations,
  } = useConfigurationStore();
  const showRedirectLoading = useUIStore((state) => state.showRedirectLoading);

  const { integration_type = null, single_logout_url: singleLogoutRedirectURL = "" } =
    domainSettings?.sso ?? {};
  const { canAccessGamification } = permissions.gamificationPermissions;
  const { mutate: getGamificationSettingsMutation } = useGetGamificationSettings();
  const { intercom_settings = null } = userIntegrations ?? {};
  const domainSettingsLocale = domainSettings?.locale;
  const localStorageLocale = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE);
  const isCatalogEnabled = Boolean(domainSettings?.external_catalog) || isAuthenticated;
  const { show_onboarding: showOnboarding, show_welcome_questions: showWelcomeQuestions } =
    userProfileData ?? {};
  const navigate = useNavigate();

  const isAuthenticatedRequestsEnabled =
    !isAuthenticated || !domainTerms ? false : domainTerms.terms === null;

  const { showLiveChat, showPhoneSupport, setLiveChatVisible, setPhoneSupportVisible } =
    useUIStore();
  const { chat_support: liveChatInPlan, phone_support: phoneSupportInPlan } =
    domainSettings?.features_allowed_in_plan ?? {};

  const handleZendeskLoaded = (): void => {
    closeAndHideZendeskWidget();
    setLiveChatVisible(false);
    setPhoneSupportVisible(false);
  };

  const shouldInitializeZendesk = isAuthenticated && (liveChatInPlan || phoneSupportInPlan);

  useZendeskService({
    zendeskKey: integrations.ZENDESK_API_KEY,
    shouldInitializeZendesk,
    defer: true,
    onLoaded: handleZendeskLoaded,
    showLiveChat,
    showPhoneSupport,
  });

  // get domain settings
  const {
    status: domainSettingsStatus,
    error: domainSettingsError,
    refetch,
  } = useQuery(queryKeys.domainSettings, getDomainSettings, {
    onSuccess: (res) => {
      setDomainSettings(res._data);
      const { theme } = res._data;
      setActiveTheme(theme);
    },
    refetchOnWindowFocus: !isAuthenticated,
    cacheTime: 0,
  });

  // get announcements
  useQuery([queryKeys.announcements.announcements], getAnnouncements, {
    onSuccess: (res) => {
      setAnnouncements(res._data);
    },
    cacheTime: 0,
  });

  useEffect(() => {
    if (isAuthenticated) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isSSOenabled = ["saml", "oidc"].some((type) => type === integration_type);

  // get saml and oidc sso domain settings
  useQuery(queryKeys.ssoDomainSettings, () => getSSOAuthUrls(), {
    enabled: isSSOenabled,

    onSuccess: (res) => {
      const logoutUrl = singleLogoutRedirectURL
        ? decodeBase64(singleLogoutRedirectURL)
        : res._data.logout_url;

      // If we log in from SSO (okta for example) the key is not set in the first login
      localStorage.setItem(localStorageKeys.SSO_LOGOUT_URL, logoutUrl);
      setSsoDomainSettings(res._data);
    },
  });

  // get social media domain login/signup options
  useQuery(queryKeys.socialDomainSettings, getSocialDomainSettings, {
    enabled: domainSettings?.social_login.length !== 0,
    onSuccess: (res) => setSocialDomainSettings(res._data),
  });

  // get user profile data
  const { status: userProfileStatus, error: userProfileError } = useGetUserProfile({
    enabled: isAuthenticated,
    cacheTime: 0,
    onSuccess: (res) => {
      setUserProfile(res._data);

      isAuthenticated && canAccessGamification()
        ? getGamificationSettingsMutation()
        : setGamificationSettings(null);
    },
    refetchOnWindowFocus: true,
  });

  const isImpersonationEnabled = userProfileData?.impersonated;

  // get domain terms
  const { status: domainTermsStatus, error: domainTermsError } = useQuery(
    queryKeys.domainTerms.terms,
    getDomainTerms,
    {
      cacheTime: 0,
      enabled: isAuthenticated && Boolean(!isImpersonationEnabled),
      onSuccess: (res) => {
        setDomainTerms(res._data);

        if (res._data.terms) {
          queryClient.cancelQueries();
        }
      },
    },
  );

  //get user statistics
  useQuery([queryKeys.userStatistics], () => getUserStatistics(), {
    enabled: isAuthenticatedRequestsEnabled,
    onSuccess: (res) => setUserStatistics(res._data),
  });

  //get catalog settings
  useQuery([queryKeys.catalogSettings], () => getCatalogSettings(), {
    onSuccess: (res) => setCatalogSettings(res._data),
    refetchOnWindowFocus: true,
    cacheTime: 0,
    enabled: isCatalogEnabled,
  });

  // get integration settings
  useQuery([queryKeys.userIntegrations], getUserIntegrations, {
    enabled: isAuthenticated,
    onSuccess: (res) => setUserIntegrations(res._data),
  });

  useEffect(() => {
    // If the domain settings locale is set and the user has not selected a locale, set the domain settings locale
    // If the user has selected a locale, set the user's locale

    if (domainSettingsLocale && !localStorageLocale) {
      languageChange(domainSettingsLocale);
    } else if (localStorageLocale) {
      languageChange(localStorageLocale);
    }
  }, [domainSettingsLocale, localStorageLocale]);

  useEffect(() => {
    if (userProfileData) {
      // when logged in branch and branch locale is set to a specific locale set domain locale, else set user's locale
      const locale =
        domainSettings?.user_selected_locale === false
          ? domainSettings.locale
          : userProfileData.locale;
      languageChange(locale);

      const localStorageLocale = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE);

      // Used in public routes and non react helpers
      if (localStorageLocale !== locale) {
        localStorage.setItem(localStorageKeys.LANGUAGE_LOCALE, locale);
      }
    }
  }, [domainSettings?.locale, domainSettings?.user_selected_locale, userProfileData]);

  useEffect(() => {
    if (
      !domainSettings?.is_sanctioned &&
      !showWelcomeQuestions &&
      showOnboarding &&
      location.pathname !== URLS.onboardingOptions &&
      location.pathname !== URLS.onboardingNotAvailable
    ) {
      navigate(URLS.onboardingOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnboarding]);

  // required request are fetching data
  const isLoading = [domainSettingsStatus, domainTermsStatus, userProfileStatus].some(
    (status) => status === "loading",
  );
  if (isLoading) return <Loader fullScreen />;

  // branch is inactive
  if (isBranchInactive([domainSettingsError, domainTermsError, userProfileError]))
    return <InactiveBranchError />;

  // an error occurred
  if (domainSettingsError || domainTermsError || userProfileError) {
    //Check for invalid domain => redirect to given URL
    if (domainSettingsError) {
      const domainSettingsAxiosError = domainSettingsError as AxiosError;
      const domainSettingsErrorId = domainSettingsAxiosError?.response?.data._errors[0]?.id;

      if (
        domainSettingsErrorId === "service_unavailable.invalid_domain" ||
        domainSettingsErrorId === "not_found.invalid_domain"
      ) {
        const redirectionURL = `https://${domainSettingsAxiosError?.response?.data._meta?.redirect_url}`;
        window.location.replace(redirectionURL);

        return <Loader fullScreen />;
      }
    }

    return <InternalServerError />;
  }

  // redirecting to core
  if (showRedirectLoading) return <AutologinLoader isFullScreen={true} />;

  return (
    <IntercomProvider appId={intercom_settings?.app_id ?? ""}>
      <Toaster />
      {children}
    </IntercomProvider>
  );
};

export default LayoutWrapper;
